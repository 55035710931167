.homePage{
    padding: 8rem 7rem;
    max-width: 900px;


    &-logo{
        img{
            max-width: 120px;
            max-height: 120px;
        }
        margin-bottom: 40px;
    }

    &-welcome{
        color: #FFF;
        h1{
            font-size: clamp(65px, 10vw, 120px);
            line-height: 1.25;
            font-weight: 600;
            font-family: 'Inter', sans-serif;
            margin: 0;
        }
        p{
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.75;
            font-weight: 300;

            font-family: 'Inter', sans-serif;
            margin-bottom: 30px;
        }
    }
    
    &-links{
        display: flex;
        flex-wrap: wrap;
        gap: 35px;
    }
}

@media screen and (max-width: 768px) {
    .homePage{
        padding: 3rem 1.8rem;
    }
}